import React from 'react';
import { BiSearch } from 'react-icons/bi';
import '../Styles/Input.css';
function SearchInput({ placeholder, searchTerm, onChange, type, name = '' }) {
  return (
    <div className='search-input'>
      <input
        type={type}
        placeholder={placeholder}
        value={searchTerm}
        onChange={onChange}
        className='input-field'
        name={name}
      />

      <span className='search-icon'>
        {' '}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='17'
          height='17'
          viewBox='0 0 17 17'
          fill='none'
        >
          <path
            d='M11.67 10.859C12.6175 9.71072 13.216 8.23789 13.216 6.61527C13.216 2.97063 10.2486 0 6.60799 0C2.96736 0 0 2.97063 0 6.61527C0 10.2599 2.96736 13.2305 6.60799 13.2305C8.20389 13.2305 9.70004 12.6564 10.8471 11.6828L15.9839 16.8253C16.1085 16.9501 16.2582 17 16.4078 17C16.5574 17 16.707 16.9501 16.8317 16.8253C17.0561 16.6006 17.0561 16.2012 16.8317 15.9765L11.67 10.859ZM6.58306 12.0323C3.59076 12.0323 1.17198 9.61087 1.17198 6.61527C1.17198 3.61968 3.59076 1.19824 6.58306 1.19824C9.57536 1.19824 11.9941 3.61968 11.9941 6.61527C11.9941 9.61087 9.57536 12.0323 6.58306 12.0323Z'
            fill='#444444'
          />
        </svg>
      </span>
    </div>
  );
}

export default SearchInput;
