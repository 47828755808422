import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DiscountModal from '../Components/DiscountModal';
import LoadingIcon from '../Components/LoadingIcon';
import { RepairProgress } from '../Components/RepairProgress';
import { updateRepairStatus } from '../Functions/updateRepairStatus.function';
import '../Styles/RepairSheet/RepairListing.css';

function NewRepairListing({
  loading,
  repairList,
  openModal,
  setRepairId,
  setLoading,
  isModalOpen,
  closeModal,
  repairId,
}) {
  const updateRepair = (id, status) => {
    const data = { status };
    updateRepairStatus(id, data)
      .then((res) => toast.success(res))
      .catch((e) =>
        toast.error(e.message || 'There was an error updating the repair')
      )
      .finally(() => setLoading());
  };

  return (
    <>
      <div className='repair-listing-table'>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20,
            }}
          >
            <LoadingIcon />
          </div>
        ) : repairList && repairList.length > 0 ? (
          <table className='blueTable'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Bar Code</th>
                <th>Make & Model</th>
                <th>Customer Name</th>
                <th>Contact</th>
                <th>Password</th>
                <th>Faults</th>
                <th style={{ width: '10%' }}>Status</th>
                <th style={{ width: '10%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {repairList.map((repair, index) => (
                <tr key={repair._id}>
                  <td>{new Date(repair.date).toLocaleString()}</td>
                  <td>{repair.barCode}</td>
                  <td>{repair.makeAndModel}</td>
                  <td>{repair.customerName}</td>
                  <td>{repair.contact}</td>
                  <td>{repair.password}</td>
                  <td>{repair.faults}</td>
                  <td>
                    <RepairProgress
                      selectedValue={repair.status}
                      onChange={(s) => updateRepair(repair._id, s)}
                    />
                  </td>
                  <td>
                    <Link
                      to={`/buyer/repair-sheet/detail?repairId=${repair._id}`}
                    >
                      <button className='submit'>View Details</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>
            <table className='blueTable'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Barcode</th>
                  <th>Make & Model</th>
                  <th>Customer Name</th>
                  <th>Status</th>
                  <th style={{ width: '10%' }}>Actions</th>
                </tr>
              </thead>
            </table>
            <div className='no-data'>
              <p>No data available</p>
            </div>
          </>
        )}
      </div>
      <DiscountModal
        repairId={repairId}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
}

export default NewRepairListing;
