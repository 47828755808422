import React, { useEffect, useState } from 'react';
import BuyerHeader from '../Components/Header/BuyerHeader';
import SearchInput from '../Components/Search';
import Spinner from '../Components/Spinner';

import axios from 'axios';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingIcon from '../Components/LoadingIcon';
import { RepairProgress } from '../Components/RepairProgress';
import { updateRepairStatus } from '../Functions/updateRepairStatus.function';
import '../Styles/RepairSheet/RepairListing.css';

function LabsListing() {
  const [repairList, setRepairList] = useState([]);
  // const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  const [searchItem, setSearchItem] = useState({
    invoice: '',
    barCode: '',
    customerName: '',
    makeModel: '',
  });

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  const debouncedFetchRepairList = debounce(() => {
    fetchRepairList();
  }, 500);

  const [repairStats, setRepairStats] = useState({
    inProgress: 0,
    fixed: 0,
    unfixed: 0,
  });

  useEffect(() => {
    fetchRepairStats();
    fetchRepairList();
  }, []);

  useEffect(() => {
    debouncedFetchRepairList();
  }, [searchItem]);

  const filterValidFields = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value) acc['search'] = value;
      return acc;
    }, {});
  };

  const fetchRepairList = async () => {
    try {
      setLoading(true);
      const searchTxt = filterValidFields(searchItem);
      let url = !Object.keys(searchTxt).length
        ? `/api/v1/repair`
        : `/api/v1/repair?search=${searchTxt.search}`;
      url += `${url.includes('?') ? '&' : '?'
        }repair?sentToLab=${true}&shop=${loggedInUserShopId}&isAccessory=${true}`;

      // const apiUrl = searchItem
      //   ? `repair?sentToLab=${true}&shop=${loggedInUserShopId}&isAccessory=${true}&search=${searchItem}`
      //   : `repair?sentToLab=${true}&shop=${loggedInUserShopId}&isAccessory=${true}`;
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${url}`
      );
      const repairData = response.data.data;
      setRepairList(repairData);
    } catch (error) {
      console.error('Error while fetching repair entries: ', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRepairStats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/repair/stat?shop=${loggedInUserShopId}`
      );
      const statsData = response.data.data[0];

      // Update the state with the fetched repair stats
      setRepairStats({
        inProgress: statsData.inProgress || 0,
        fixed: statsData.fixed || 0,
        unfixed: statsData.unfixed || 0,
      });
    } catch (error) {
      console.error('Error while fetching repair stats: ', error);
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const updateRepair = (id, status) => {
    const data = { status };
    updateRepairStatus(id, data)
      .then((res) => toast.success(res))
      .catch((e) =>
        toast.error(e.message || 'There was an error updating the repair')
      )
      .finally(() => setLoading());
  };

  const handleSearcTxt = (e) => {
    const { name, value } = e.target;
    setSearchItem({
      invoice: name === 'invoice' ? value : '',
      barCode: name === 'barCode' ? value : '',
      customerName: name === 'customerName' ? value : '',
      makeModel: name === 'makeModel' ? value : '',
    });
  };

  return (
    <>
      <BuyerHeader navs='2' />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='repair-sheet-container'>
          <div className='repair-listing'>
            <div className='search-row' style={{ marginTop: '20px' }}>
              <SearchInput
                placeholder='Search By Invoice No'
                searchTerm={searchItem.invoice}
                onChange={handleSearcTxt}
                name='invoice'
              />
              <SearchInput
                placeholder='Search By Customer Name'
                searchTerm={searchItem.customerName}
                onChange={handleSearcTxt}
                name='customerName'
              />
              <SearchInput
                placeholder='Search By Make & Model'
                searchTerm={searchItem.makeModel}
                onChange={handleSearcTxt}
                name='makeModel'
              />
              <SearchInput
                placeholder='Search by Barcode'
                searchTerm={searchItem.barCode}
                onChange={handleSearcTxt}
                name='barCode'
              />
              {/* <button className='search-button'>Search</button> */}
            </div>
            <div className='row'></div>
            <div className='repair-listing-table'>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 20,
                  }}
                >
                  <LoadingIcon />
                </div>
              ) : repairList && repairList.length > 0 ? (
                <table className='blueTable'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Barcode</th>
                      <th>Make & Model</th>
                      <th>Customer Name</th>
                      <th>Contact</th>
                      <th>Password</th>
                      <th>Faults</th>
                      <th>Status</th>
                      <th>Notes</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {repairList
                      .filter((item) => item.sentToLab == true)
                      .map((repair, index) => (
                        <tr key={repair._id}>
                          <td>{new Date(repair.date).toLocaleDateString()}</td>
                          <td>{repair.barCode}</td>
                          <td>{repair.makeAndModel}</td>
                          <td>{repair.customerName}</td>
                          <td>{repair.contact}</td>
                          <td>{repair.password}</td>
                          <td>{repair.faults}</td>
                          <td>
                            <RepairProgress
                              selectedValue={repair.status}
                              onChange={(s) => updateRepair(repair._id, s)}
                            />
                          </td>
                          <td>
                            <p className='overflowtexter'>
                              {repair.address || '-'}
                            </p>
                          </td>
                          <td>
                            <Link
                              to={`/buyer/repair-sheet/detail?repairId=${repair._id}`}
                            >
                              <button className='submit'>View Details</button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <>
                  <table className='blueTable'>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Barcode</th>
                        <th>Make & Model</th>
                        <th>Customer Name</th>
                        <th>Contact</th>
                        <th>Password</th>
                        <th>Faults</th>
                        <th>Status</th>
                        <th>Entered By</th>
                        <th>Notes</th>
                        <th style={{ width: '10%' }}>Actions</th>
                      </tr>
                    </thead>
                  </table>
                  <div className='no-data'>
                    <p>No data available</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LabsListing;
