// userActions.js
import { loginStart, loginSuccess, loginFailure, logout } from "./userSlicer";
import axios from "axios";

// Dummy token (replace with your desired token value)
const dummyToken = "yourDummyTokenValue";

// Async action to log in the user
export const loginUser = (credentials) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/authentication/shopLogin`,
      credentials
    );

    const user = response.data.data;
    const token = dummyToken;

    // Store user data and token in local storage (you may want to implement this securely)
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);

    dispatch(loginSuccess({ user, token }));
  } catch (error) {
    dispatch(loginFailure("Login Failed. Please check your credentials!"));
  }
};

// Action to set the user as authenticated based on a token
export const setAuthenticatedUser = (user, token) => (dispatch) => {
  dispatch(loginSuccess({ user, token }));
};

// Action to log out the user
export const logoutUser = () => (dispatch) => {
  dispatch(logout());
};
