import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import SearchInput from '../Components/Search';
import '../Styles/RepairSheet/RepairListing.css';
import NewRepairListing from './NewRepairListing';

function Repair({ nav }) {
  const [repairList, setRepairList] = useState([]);
  // const [searchTerm, setSearchTerm] = useState('');
  const [searchItem, setSearchItem] = useState({
    imei: '',
    barCode: '',
    customerName: '',
    makeModel: '',
  });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [repairId, setRepairId] = useState(null);
  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;
  const debouncedFetchRepairList = debounce(() => {
    fetchRepairList();
  }, 500);

  useEffect(() => {
    fetchRepairStats();
    fetchRepairList();
  }, []);

  useEffect(() => {
    debouncedFetchRepairList();
  }, [searchItem]);

  const filterValidFields = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value) acc['search'] = value;
      return acc;
    }, {});
  };

  const fetchRepairList = async () => {
    try {
      setLoading(true);
      const searchTxt = filterValidFields(searchItem);
      let url = !Object.keys(searchTxt).length
        ? `/api/v1/repair`
        : `/api/v1/repair?search=${searchTxt.search}`;
      url += `${url.includes('?') ? '&' : '?'}shop=${loggedInUserShopId}`;

      //  const apiUrl = searchTerm
      //   ? `${process.env.REACT_APP_BASE_URL}/api/v1/repair?shop=${loggedInUserShopId}&search=${searchTerm}`
      //   : `${process.env.REACT_APP_BASE_URL}/api/v1/repair?shop=${loggedInUserShopId}`;
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${url}`
      );
      // const response = await axios.get(apiUrl);
      const repairData = response.data.data;
      setRepairList(repairData);
    } catch (error) {
      console.error('Error while fetching repair entries: ', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRepairStats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/repair/stat?shop=${loggedInUserShopId}`
      );
      const statsData = response.data.data[0];

      // Update the state with the fetched repair stats
    } catch (error) {
      console.error('Error while fetching repair stats: ', error);
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setRepairId(null);
    setIsModalOpen(false);
  };

  const handleSearcTxt = (e) => {
    const { name, value } = e.target;
    setSearchItem({
      imei: name === 'imei' ? value : '',
      barCode: name === 'barCode' ? value : '',
      customerName: name === 'customerName' ? value : '',
      makeModel: name === 'makeModel' ? value : '',
    });
  };

  return (
    <div className='repair-listing'>
      {nav && (
        <div className='search-row'>
          <SearchInput
            placeholder='Search by Barcode'
            searchTerm={searchItem.barCode}
            onChange={handleSearcTxt}
            name='barCode'
          />
          <SearchInput
            placeholder='Search By Customer Name'
            searchTerm={searchItem.customerName}
            onChange={handleSearcTxt}
            name='customerName'
          />
          <SearchInput
            placeholder='Search By Make & Model'
            searchTerm={searchItem.makeModel}
            onChange={handleSearcTxt}
            name='makeModel'
          />
          <SearchInput
            placeholder='Search by IMEI'
            searchTerm={searchItem.imei}
            onChange={handleSearcTxt}
            name='imei'
          />
          {/* <button className='search-button'>Search</button> */}
        </div>
      )}
      <NewRepairListing
        loading={loading}
        setLoading={() => fetchRepairList()}
        repairList={repairList}
        openModal={openModal}
        setRepairId={setRepairId}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        repairId={repairId}
      />
    </div>
  );
}

export default Repair;
