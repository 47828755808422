import React from 'react';
import '../Styles/Spinner/Spinner.css'
const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className='spin-container'>
      <div className="spinner"></div>
      <p>Loading...</p>
      </div>
    </div>
  );
};

export default Spinner;


export const Spinnie = () => {
  return (
    <div className="spinner-containers">
      <div className='spin-containers'>
      <div className="spinners"></div>
      {/* <p>Loading...</p> */}
      </div>
    </div>
  );
};


