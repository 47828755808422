import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BuyerHeader from '../Components/Header/BuyerHeader';
import Spinner from '../Components/Spinner';
import '../Styles/RepairSheet/RepairSheet.css';
import { Check, CheckBox, InputBox } from './InputBox';
import Repair from './Repair';

function RepairSheet() {
  const [activeButton, setActiveButton] = useState('button1');
  const [isLoading, setIsLoading] = useState(true);
  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  const [formData, setFormData] = useState({
    shopName: JSON.parse(localStorage.getItem('user'))?.name ?? undefined,
    shop: loggedInUserShopId,
    contact: '',
    password: '',
    invoice: '',
    sparePhone: '',
    network: '',
    date: '',
    returnDate: '',
    customerName: '',
    makeAndModel: '',
    address: '',
    imei: '',
    isRefix: false,
    sentToLab: false,
    faults: '',
    hasAccessories: false,
    hasBattery: false,
    hasSimCard: false,
    hasMemoryCard: false,
    hasCover: false,
    other: null,
    charges: '',
    deposit: 0,
    balance: '',
    enteredBy: '',
    policyConfirm: false,
    faultChecklist: {
      data: { before: false, after: false },
      backCamera: { before: false, after: false },
      frontCamera: { before: false, after: false },
      earpiece: { before: false, after: false },
      buttons: { before: false, after: false },
      chargingPorts: { before: false, after: false },
      mic: { before: false, after: false },
      wifi: { before: false, after: false },
      signalsCalling: { before: false, after: false },
      ringMusic: { before: false, after: false },
      touch: { before: false, after: false },
      lcd: { before: false, after: false },
      sensor: { before: false, after: false },
      headphones: { before: false, after: false },
      loudspeaker: { before: false, after: false },
      repairedBefore: { before: false, after: false },
    },
  });

  const resetForm = () => {
    setFormData(st => ({
      ...st,
      shopName: JSON.parse(localStorage.getItem('user'))?.name ?? undefined,
      shop: loggedInUserShopId,
      contact: '',
      password: '',
      invoice: '',
      enteredBy: '',
      sparePhone: '',
      network: '',
      returnDate: '',
      customerName: '',
      makeAndModel: '',
      address: '',
      imei: '',
      isRefix: false,
      sentToLab: false,
      faults: '',
      hasAccessories: false,
      hasBattery: false,
      hasSimCard: false,
      hasMemoryCard: false,
      hasCover: false,
      other: null,
      charges: '',
      deposit: 0,
      balance: '',
      policyConfirm: false,
      faultChecklist: {
        data: { before: false, after: false },
        backCamera: { before: false, after: false },
        frontCamera: { before: false, after: false },
        earpiece: { before: false, after: false },
        buttons: { before: false, after: false },
        chargingPorts: { before: false, after: false },
        mic: { before: false, after: false },
        wifi: { before: false, after: false },
        signalsCalling: { before: false, after: false },
        ringMusic: { before: false, after: false },
        touch: { before: false, after: false },
        lcd: { before: false, after: false },
        sensor: { before: false, after: false },
        headphones: { before: false, after: false },
        loudspeaker: { before: false, after: false },
        repairedBefore: { before: false, after: false },
      },
    }));
  };
  const datePickerStyles = {
    input: {
      flex: '1 1',
      background: '#f5f5f5',
      padding: '8px', // Adjust padding as needed
      borderRadius: '10px',
      outline: 'none',
      color: '#444',
      fontFamily: 'Inter Tight',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    // Add more styles as needed
  };
  const handleInputChange = (field, value, item, type) => {
    if (item && type) {
      setFormData((prevData) => ({
        ...prevData,
        faultChecklist: {
          ...prevData.faultChecklist,
          [item]: {
            ...prevData.faultChecklist[item],
            [type]: value,
          },
        },
      }));
    } else {
      // Update the main fields like charges, deposit, and calculate balance
      setFormData((prevData) => {
        const updatedData = {
          ...prevData,
          [field]: value,
        };

        // Calculate balance if charges or deposit changes
        if (field === 'charges' || field === 'deposit') {
          const charges =
            parseFloat(field === 'charges' ? value : prevData.charges) || 0;
          const deposit =
            parseFloat(field === 'deposit' ? value : prevData.deposit) || 0;
          const balance = charges - deposit;
          updatedData.balance = balance.toString();
        }

        return updatedData;
      });
    }
  };

  useEffect(() => {
    // Check if invoice is not already set (i.e., it's the first render)
    if (!formData.invoice) {
      // Generate a 6-digit unique invoice number
      const invoiceNumber = Math.floor(100000 + Math.random() * 900000);
      setFormData((prevData) => ({
        ...prevData,
        invoice: invoiceNumber.toString(),
      }));
    }

    const currentDate = new Date();

    // Get the components of the date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Construct the formatted date
    const formattedDate = `${year}-${month}-${day}`;

    setFormData((prevData) => ({
      ...prevData,
      date: dayjs(new Date()),
    }));

    const user = JSON.parse(localStorage.getItem('user'));

    setFormData((prevData) => ({
      ...prevData,
      shopName: user.name,
    }));
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  // Function to handle form submission
  const saveData = async () => {
    const {
      invoice,
      other,
      policyConfirm2,
      policyConfirm3,
      ...formDataWithoutInvoice
    } = formData;

    // Set otherText to null if it is empty
    const modifiedFormData = {
      ...formDataWithoutInvoice,
      other: other?.trim() === '' ? null : other,
      date: formData.date.toDate(),
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/repair?shop=${loggedInUserShopId}`,
        modifiedFormData
      );
      toast.success('Repair sheet has been created successfully');
      resetForm(); // Reset the form after a successful save
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error('Error while creating repair sheet!');
      }
    }
  };

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <>
      <BuyerHeader navs='3' />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='repair-sheet-container'>
          <div className='buttons-tab-container'>
            <button
              onClick={() => handleButtonClick('button1')}
              style={{
                backgroundColor:
                  activeButton === 'button1' ? '#FF7C5C' : '#F5F5F5',
                color: activeButton === 'button1' ? '#fff' : '#000',
              }}
            >
              Repair Sheet
            </button>
            <button
              onClick={() => handleButtonClick('button2')}
              style={{
                backgroundColor:
                  activeButton === 'button2' ? '#FF7C5C' : '#F5F5F5',
                color: activeButton === 'button2' ? '#fff' : '#000',
              }}
            >
              Repair Listing
            </button>
          </div>
          {activeButton === 'button1' && (
            <>
              <div className='inputs-container'>
                <div className='inputs-container-upper'>
                  <InputBox
                    text='*Shop Name'
                    placeholder='Enter Shop Name'
                    type='text'
                    value={formData.shopName}
                    onChange={(value) => handleInputChange('shopName', value)}
                    disabled={true}
                  />
                  <div className='input-box'>
                    <label>*Date</label>
                    <DesktopDateTimePicker
                      placeholder='Enter Date'
                      inputFormat={() => 'From'}
                      onChange={(value) => {
                        handleInputChange('returnDate', value);
                      }}
                      defaultValue={dayjs(new Date())}
                      TextFieldComponent={(props) => (
                        <input
                          {...props}
                          style={datePickerStyles}
                          placeholder='Custom Placeholder'
                        />
                      )}
                      // value={formData.date}
                      InputLabelProps={{
                        shrink: true, // Ensure the label is not floating
                      }}
                    />
                  </div>
                  <InputBox
                    text='*Invoice'
                    placeholder='Enter Invoice No'
                    type='text'
                    value={formData.invoice}
                    onChange={(value) => handleInputChange('invoice', value)}
                    disabled={true}
                  />
                </div>
                <div className='inputs-container-upper'>
                  <InputBox
                    text='*Make & Model'
                    placeholder='Enter Make & Model'
                    type='text'
                    value={formData.makeAndModel}
                    onChange={(value) =>
                      handleInputChange('makeAndModel', value)
                    }
                  />

                  <InputBox
                    text='*Faults'
                    placeholder=' Faults'
                    type='text'
                    value={formData.faults}
                    onChange={(value) => handleInputChange('faults', value)}
                  />
                  <InputBox
                    text='Network'
                    placeholder='Enter Network'
                    type='text'
                    value={formData.network}
                    onChange={(value) => handleInputChange('network', value)}
                  />
                </div>

                <div className='inputs-container-upper'>
                  <InputBox
                    text='*Customer Name'
                    placeholder=''
                    type='text'
                    value={formData.customerName}
                    onChange={(value) =>
                      handleInputChange('customerName', value)
                    }
                  />
                  <InputBox
                    text='*Contact No '
                    placeholder='Enter Contact No'
                    type='text'
                    value={formData.contact}
                    onChange={(value) => handleInputChange('contact', value)}
                  />
                  <InputBox
                    text='Password'
                    placeholder='Enter Password'
                    type='text'
                    value={formData.password}
                    onChange={(value) => handleInputChange('password', value)}
                  />
                </div>
                <div className='inputs-container-upper'>
                  <InputBox
                    text='IMEI'
                    placeholder='Enter Numeric Values'
                    type='text'
                    value={formData.imei}
                    onChange={(value) => handleInputChange('imei', value)}
                  />
                  <InputBox
                    text='Entered By'
                    placeholder='Entered by'
                    type='text'
                    value={formData.enteredBy}
                    onChange={(value) => handleInputChange('enteredBy', value)}
                  />

                  <InputBox
                    text='Spare Phone'
                    placeholder='Enter'
                    type='text'
                    value={formData.sparePhone}
                    onChange={(value) => handleInputChange('sparePhone', value)}
                  />
                </div>
                <div className='inputs-container-upper'>
                  <div className='input-box'>
                    <label>Notes</label>
                    <textarea
                      style={{ minHeight: '180px' }}
                      value={formData.address}
                      onChange={(e) =>
                        handleInputChange('address', e.target.value)
                      }
                    />
                  </div>
                  <div className='column-container'>
                    <div className='input-box'>
                      <label>Sent To Lab</label>
                      <div
                        style={{
                          display: 'flex',
                          gap: '37px',
                          marginTop: '13px',
                        }}
                      >
                        <CheckBox
                          text={'Yes'}
                          checked={formData.sentToLab}
                          onChange={() => {
                            handleInputChange('sentToLab', true);
                          }}
                        />
                        <CheckBox
                          text={'No'}
                          checked={!formData.sentToLab}
                          onChange={() => {
                            handleInputChange('sentToLab', false);
                          }}
                        />
                      </div>
                    </div>
                    <div className='input-box'>
                      <label>Exp.Return</label>
                      <DesktopDatePicker
                        placeholder='Enter Date'
                        inputFormat={() => 'From'}
                        onChange={(value) =>
                          handleInputChange('returnDate', value)
                        }
                        TextFieldComponent={(props) => (
                          <input
                            {...props}
                            style={datePickerStyles}
                            placeholder='Custom Placeholder'
                          />
                        )}
                        InputLabelProps={{
                          shrink: true, // Ensure the label is not floating
                        }}
                      />
                    </div>
                  </div>
                  <div className='column-container'>
                    <div className='input-box'>
                      <label>Refix</label>
                      <div
                        style={{
                          display: 'flex',
                          gap: '37px',
                          marginTop: '13px',
                        }}
                      >
                        <CheckBox
                          text={'Yes'}
                          checked={formData.isRefix}
                          onChange={() => handleInputChange('isRefix', true)}
                        />
                        <CheckBox
                          text={'No'}
                          checked={!formData.isRefix}
                          onChange={() => handleInputChange('isRefix', false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='inputs-container-bottom'>
                  <CheckBox
                    text={'Accessories'}
                    checked={formData.hasAccessories}
                    onChange={(checked) =>
                      handleInputChange('hasAccessories', checked)
                    }
                  />
                  <CheckBox
                    text={'Battery'}
                    checked={formData.hasBattery}
                    onChange={(checked) =>
                      handleInputChange('hasBattery', checked)
                    }
                  />
                  <CheckBox
                    text={'Sim Card'}
                    checked={formData.hasSimCard}
                    onChange={(checked) =>
                      handleInputChange('hasSimCard', checked)
                    }
                  />
                  <CheckBox
                    text={'Memory Card'}
                    checked={formData.hasMemoryCard}
                    onChange={(checked) =>
                      handleInputChange('hasMemoryCard', checked)
                    }
                  />
                  <CheckBox
                    text={'Cover'}
                    checked={formData.hasCover}
                    onChange={(checked) =>
                      handleInputChange('hasCover', checked)
                    }
                  />
                  <input
                    type='text'
                    className='input-foam'
                    value={formData.other}
                    onChange={(e) => handleInputChange('other', e.target.value)}
                  />
                </div>
                <div className='inputs-container-upper'>
                  <div className='balance-container'>
                    <p>Charges</p>
                    <input
                      // disabled
                      min={0}
                      type='number'
                      className='input-foam'
                      value={formData.charges}
                      onChange={(e) =>
                        handleInputChange('charges', e.target.value)
                      }
                    />
                  </div>
                  <div className='balance-container'>
                    <p>Deposit</p>
                    <input
                      // disabled
                      min={0}
                      type='number'
                      className='input-foam'
                      value={formData.deposit}
                      onChange={(e) =>
                        handleInputChange('deposit', e.target.value)
                      }
                    />
                  </div>
                  <div className='balance-container'>
                    <p>Balance</p>
                    <input
                      type='number'
                      className='input-foam'
                      style={{ background: '#D9D9D9' }}
                      value={formData.balance}
                      onChange={(e) =>
                        handleInputChange('balance', e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='bottom-sheet-container'>
                <div className='table-left'>
                  <table class='blueTables'>
                    <thead>
                      <tr>
                        <th>Fault Checklist</th>
                        <th>
                          Before
                          {/* <CheckBox text="Before" /> */}
                        </th>
                        <th>
                          After
                          {/* <CheckBox text="After" /> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(formData.faultChecklist).map(
                        ([item, values], index) => (
                          <tr key={index}>
                            <td>
                              <span>{item}</span>
                            </td>
                            <td>
                              <Check
                                checked={values.before}
                                onChange={(checked) =>
                                  handleInputChange(
                                    null,
                                    checked,
                                    item,
                                    'before'
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Check
                                checked={values.after}
                                onChange={(checked) =>
                                  handleInputChange(
                                    null,
                                    checked,
                                    item,
                                    'after'
                                  )
                                }
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='sheet-right'>
                  <h3>Dear Customers,</h3>
                  <p>
                    1) All parts used during repair will be replacement parts
                    not from original brand.
                  </p>
                  <p>
                    2) Please take out any SIM and memory cards. We cannot
                    accept any responsibility or liability for these.
                  </p>
                  <p>
                    3) We don't do any refund at all for any kind of repairs. If
                    after repairing issue still exist. We will try to re fix it
                    or offer only credit note which will be valid for six months
                    these rules will not affect your statutory rights.
                  </p>
                  <p>
                    4) In Touch & LCD repair company only guarantee for colour
                    pixels or if touch not working. There is no refund or
                    exchange for broken LCD or Touch screens.
                  </p>
                  <p>
                    5) Any repair done by us will be covered by our 30 days
                    warranty if the same fault occurs. But this warranty will
                    not cover any accident damage of Touch & LCD screens.
                  </p>
                  <p>
                    6) We only repair the fault that your phone camelin forif we
                    repair the phone & on testing we notice it has additional
                    faults, you will be quoted separately for those faults.
                  </p>
                  <p>
                    7) Any phone which is coming back for refix, during refix if
                    we found an additional fault with your phone, you will be
                    quoted separately for those faults.
                  </p>
                  <p>
                    8) If we successfully unlock or repair your phone & phone
                    turns out to be barred the quoted repair price will still
                    stand.
                  </p>
                  <p>
                    9) Please indicate if the data on your phone is important &
                    you would like us to try our best to save itHowever we
                    cannot guarantee your data or settings on the phone will be
                    saved during our repairs or unlocking.
                  </p>
                  <p>
                    10) Our repairs will void your phone warranty. Please check
                    you are happy with this before you hand over your phone.
                  </p>
                  <h3>Customer's Declaration</h3>

                  <div className='checkbox-condition'>
                    <CheckBox
                      text='I confirm that I have read and understood the terms and conditions given above.'
                      checked={formData.policyConfirm}
                      onChange={(checked) =>
                        handleInputChange('policyConfirm', checked)
                      }
                    />
                  </div>
                  {/* <h2>Customer’s Signature</h2> */}

                  <button className='save' onClick={saveData}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='15'
                      height='16'
                      viewBox='0 0 16 17'
                      fill='none'
                    >
                      <path
                        d='M14.2222 9.38889V13.8333C14.2222 14.3222 13.8222 14.7222 13.3333 14.7222H2.66667C2.17778 14.7222 1.77778 14.3222 1.77778 13.8333V9.38889C1.77778 8.9 1.37778 8.5 0.888889 8.5C0.4 8.5 0 8.9 0 9.38889V14.7222C0 15.7 0.8 16.5 1.77778 16.5H14.2222C15.2 16.5 16 15.7 16 14.7222V9.38889C16 8.9 15.6 8.5 15.1111 8.5C14.6222 8.5 14.2222 8.9 14.2222 9.38889ZM8.88889 9.09556L10.56 7.42444C10.9067 7.07778 11.4667 7.07778 11.8133 7.42444C12.16 7.77111 12.16 8.33111 11.8133 8.67778L8.62222 11.8689C8.27556 12.2156 7.71556 12.2156 7.36889 11.8689L4.17778 8.67778C3.83111 8.33111 3.83111 7.77111 4.17778 7.42444C4.52444 7.07778 5.08444 7.07778 5.43111 7.42444L7.11111 9.09556V1.38889C7.11111 0.9 7.51111 0.5 8 0.5C8.48889 0.5 8.88889 0.9 8.88889 1.38889V9.09556Z'
                        fill='white'
                      />
                    </svg>{' '}
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
          {activeButton === 'button2' && <Repair nav={true} />}
        </div>
      )}
    </>
  );
}

export default RepairSheet;
