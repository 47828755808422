import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const RepairProgress = ({ onChange, selectedValue }) => {
  return (
    <FormControl fullWidth>
      <Select
        size="small"
        style={{
          backgroundColor: "rgb(245, 245, 245)",
          color: "rgb(68, 68, 68)",
          border: "1px solid rgb(204, 204, 204)",
          borderRadius: 10,
          marginBottom: "5%",
        }}
        defaultValue={selectedValue}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value={"inProgress"}>In Progress</MenuItem>
        <MenuItem value={"fixed"}>Fixed</MenuItem>
        <MenuItem value={"unfixed"}>Non Fixed</MenuItem>
      </Select>
    </FormControl>
  );
};
