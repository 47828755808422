import React from "react";
import "../Styles/Buttons/Button.css";
import { useState } from "react";
export function Button({ bgcolor, color, text, icon, onClick, hoverColor }) {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyles = {
    backgroundColor: bgcolor,
    color: color,
    cursor: 'pointer',
    transition: 'background-color 0.3s', // Smooth transition for hover effect
    ':hover': {
      backgroundColor: hoverColor,
    },
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <button className="button" style={{
        ...buttonStyles,
        backgroundColor: isHovered ? hoverColor : bgcolor,
      }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <p className="button-text">
          {icon && <span className="button-icon" > {icon}</span>}{text}
        </p>
      </button>
    </>
  );
}

export function ButtonTags({ bgcolor, color, text, icon, onClick, hoverColor }) {
  const buttonStyles = {
    backgroundColor: bgcolor,
    color: color,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: hoverColor,
    },
  };

  return (
    <>
      <button className="button-tag " style={buttonStyles} onClick={onClick}>
        <p className="button-text">
          <span className="button-icon"> {icon}</span> {text}
        </p>
      </button>
    </>
  );
}


export function MButton({ bgcolor, color, text, icon, onClick, hoverColor, }) {
  const buttonStyles = {
    backgroundColor: bgcolor,
    color: color,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: hoverColor,
    },
  };
  return (
    <>
      <button className="button-medium" style={buttonStyles} onClick={onClick}>
        <p className="button-text">
          <span className="button-icon"> {icon}</span> {text}
        </p>
      </button>
    </>
  );
}


export function NavButton({ bgcolor, color, text, icon, onClick, active, hoverColor }) {
  const buttonStyles = {
    backgroundColor: bgcolor,
    color: color,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: hoverColor,
    },
  };
  const hoverStyles = {
    backgroundColor: '#FF7C5C',
    color: '#fff',
    fill: '#fff !important'
  };
  return (
    <>
      <button className={`button-nav  ${active == true ? 'active-nav' : null}`} style={buttonStyles} onClick={onClick}>
        <p className="button-text">
          <span className="button-icon"> {icon}</span> {text}
        </p>
      </button>
    </>
  );
}

export function BuyerNavButton({ bgcolor, color, text, icon, onClick, active, hoverColor, user }) {
  const buttonStyles = {
    backgroundColor: bgcolor,
    color: color,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: hoverColor,
    },
  };
  const hoverStyles = {
    backgroundColor: '#FF7C5C',
    color: '#fff',
  };
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
      }}>
        {text === 'Logout' &&
          <div style={{
            textAlign: 'left',
            display: 'flex',
            marginRight: 'auto',
            marginLeft: '4rem',
          }}>
            <h4>Welcome to {user?.name}</h4>
          </div>}
        <button className={`button-nav  cursor-pointer ${active == true ? 'active-nav' : null}`} style={buttonStyles} onClick={onClick}>
          <p className="button-text"> {text}
          </p>
        </button>
      </div>
      <style>
        {`
          .button-nav:hover {
            background-color: ${hoverStyles.backgroundColor};
            color: ${hoverStyles.color};
          }
        `}
      </style>
    </>
  );
}
