import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
export function InputBox({
  text,
  placeholder,
  type,
  value,
  onChange,
  icon,
  disabled,
}) {
  const handleInputChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <>
      <div className='input-box'>
        <label>{text}</label>
        {icon && <span className='icon'>{icon}</span>}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          disabled={disabled ? disabled : false}
          style={{
            cursor: disabled ? 'no-drop' : 'text',
          }}
        />
      </div>
    </>
  );
}
export function InputBoxSelect({ placeholder, text, type, icon }) {
  return (
    <>
      <div className='input-box'>
        <label>{text}</label>
        {icon && <span className='icon'>{icon}</span>}
        <input type={type} placeholder={placeholder} />
        <span>
          <BiChevronDown />
        </span>
      </div>
    </>
  );
}

export function CheckBox({ text, checked, onChange, onClick }) {
  return (
    <label class='custom-checkbox'>
      <input
        type='checkbox'
        onClick={onClick}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span class='checkmark'></span>
      <p>{text}</p>
    </label>
  );
}

export function Check({ checked, onChange }) {
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <label className='custom-checkbox'>
      <input
        type='checkbox'
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <span className='checkmark'></span>
    </label>
  );
}
